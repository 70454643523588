<template>
  <v-card flat :class="{ 'd-grid': mdAndUp }" class="recent-updates-supports">
    <v-card-text class="px-0">
      <Split
        style="min-height: 100vh"
        :direction="!mdAndUp ? 'vertical' : 'horizontal'"
      >
        <SplitArea :size="70" :min-size="350" class="border-1 rounded-10">
          <v-card flat>
            <v-card dark flat class="rounded-0">
              <v-btn
                v-if="can_handle_tickets"
                @click="handleCreate('updates')"
                absolute
                bottom
                color="primary"
                small
                right
                fab
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
              <v-card-title class="primary lighten-1">
                <h3 class="text-h6 fw-700 text-center grow">Recent Updates</h3>
              </v-card-title>
            </v-card>
            <v-card-text>
              <v-timeline align-top dense v-if="recent_updates_posts.length">
                <v-hover
                  v-slot:default="{ hover }"
                  v-for="entry in recent_updates_posts"
                  :key="entry.id"
                >
                  <v-timeline-item color="white">
                    <template v-slot:icon>
                      <v-avatar class="border-2 border-color--primary">
                        <v-img
                          v-if="entry.preview_image"
                          :lazy-src="settings.loader"
                          contain
                          :src="entry.preview_image"
                        />
                        <v-icon color="primary" v-else> mdi-bug-check </v-icon>
                      </v-avatar>
                    </template>
                    <v-row class="pt-1">
                      <v-col cols="12" md="12">
                        <div class="d-flex align-center justify-space-between">
                          <strong class="primary--text">
                            {{ entry.title | ucwords }}
                          </strong>
                          <div v-if="hover && can_handle_tickets">
                            <HelpToolTip
                              text="Edit"
                              icon="mdi-circle-edit-outline"
                              @click:icon="handleEdit(entry)"
                            ></HelpToolTip>
                            <HelpToolTip
                              text="Delete"
                              icon="mdi-delete"
                              @click:icon="handleDelete(entry)"
                            ></HelpToolTip>
                          </div>
                        </div>
                        <strong class="fs-13 secondary--text">
                          {{ entry.created_at | format('lll') }}
                        </strong>
                        <div class="text-caption">
                          <Editor
                            :editable="false"
                            :value="entry.content"
                          ></Editor>
                        </div>
                      </v-col>
                    </v-row>
                  </v-timeline-item>
                </v-hover>
              </v-timeline>
              <Empty
                v-else
                headline="No recent updates yet"
                min-height="100vh"
              ></Empty>
            </v-card-text>
            <v-card-actions
              v-if="recent_updates_posts_next_page_url"
              class="d-flex align-center justify-center"
            >
              <v-btn
                :loading="recent_updates_posts_loading_more"
                @click="fetchMoreUpcomingUpdates()"
                depressed
                :disabled="recent_updates_posts_loading_more"
                class="text-none px-5"
                color="primary"
              >
                Load previous updates
              </v-btn>
            </v-card-actions>
          </v-card>
        </SplitArea>
        <SplitArea :size="30" :min-size="420" class="border-1 rounded-10">
          <v-card flat class="fill-height">
            <v-card dark flat class="rounded-0">
              <v-btn
                v-if="can_handle_tickets"
                absolute
                bottom
                color="primary"
                small
                right
                fab
                @click="handleCreate('upcoming-updates')"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
              <v-card-title class="primary lighten-1">
                <h3 class="text-h6 fw-700 text-center grow">
                  Upcoming Updates
                </h3>
              </v-card-title>
            </v-card>
            <v-divider />
            <Empty
              v-if="!upcoming_updates_posts.length"
              headline
              min-height="100vh"
            ></Empty>
            <v-hover
              v-slot:default="{ hover }"
              v-for="post1 in upcoming_updates_posts"
              :key="post1.id"
            >
              <v-card class="ma-2 rounded-10" flat outlined>
                <v-card-text>
                  <div class="primary--text subtitle-2">
                    {{ post1.title | ucwords }}
                  </div>
                  <v-img
                    height="150"
                    contain
                    :src="
                      post1.preview_image
                        ? post1.preview_image
                        : settings.noImagePreview
                    "
                  >
                  </v-img>
                </v-card-text>
                <v-expand-transition>
                  <v-card
                    v-if="hover"
                    class="
                      transition-fast-in-fast-out
                      v-card--reveal
                      overflow-auto
                    "
                    style="height: 100%"
                  >
                    <v-card-text class="pb-0">
                      <div
                        class="
                          d-flex
                          align-center
                          fullwidth
                          justify-space-between
                        "
                      >
                        <strong class="subtitle-1 primary--text">
                          {{ post1.title | ucwords }}
                        </strong>
                        <div v-if="can_handle_tickets">
                          <HelpToolTip
                            text="Edit"
                            icon="mdi-circle-edit-outline"
                            @click:icon="handleEdit(post1)"
                          ></HelpToolTip>
                          <HelpToolTip
                            text="Delete"
                            icon="mdi-delete"
                            @click:icon="handleDelete(post1)"
                          ></HelpToolTip>
                        </div>
                      </div>
                      <Editor :editable="false" :value="post1.content"></Editor>
                    </v-card-text>
                  </v-card>
                </v-expand-transition>
              </v-card>
            </v-hover>
            <v-card-actions
              v-if="upcoming_updates_posts_next_page_url"
              class="d-flex align-center justify-center"
            >
              <v-btn
                :loading="upcoming_updates_posts_loading_more"
                @click="fetchMoreRecentUpdates()"
                depressed
                :disabled="upcoming_updates_posts_loading_more"
                class="text-none px-5"
                color="primary"
              >
                Load more upcoming updates
              </v-btn>
            </v-card-actions>
          </v-card>
        </SplitArea>
      </Split>
    </v-card-text>
    <PostDialog
      :type="activePostType"
      :item="activePost"
      v-model="post_dialog"
      @save="savePost"
      @update="updatePost"
    ></PostDialog>
  </v-card>
</template>

<script>
import PostDialog from '../components/PostDialog.vue'
import request from '@/services/axios_instance'
import { support_mixin } from '../support_mixin'
import Editor from '@/common/Editor/Editor.vue'
import { mapGetters } from 'vuex'
export default {
  components: { PostDialog, Editor },
  mixins: [support_mixin],
  data: () => ({
    post_dialog: false,
    activePost: null,
    activePostType: 'updates', //upcoming-updates

    recent_updates_posts: [],
    recent_updates_posts_next_page_url: null,
    recent_updates_posts_loading: false,
    recent_updates_posts_loading_more: false,

    upcoming_updates_posts: [],
    upcoming_updates_posts_next_page_url: null,
    upcoming_updates_posts_loading: false,
    upcoming_updates_posts_loading_more: false
  }),
  created() {
    this.fetchUpcomingUpdates()
    this.fetchRecentUpdates()
  },
  methods: {
    fetchUpcomingUpdates() {
      this.upcoming_updates_posts_loading = true
      request
        .get(`api/supports/post?type=upcoming-updates`)
        .then(({ data }) => {
          this.upcoming_updates_posts_next_page_url = data.next_page_url
          this.upcoming_updates_posts = data.data
        })
        .finally(() => (this.upcoming_updates_posts_loading = false))
    },
    fetchMoreUpcomingUpdates() {
      this.upcoming_updates_posts_loading_more = true
      request
        .get(this.upcoming_updates_posts_next_page_url)
        .then(({ data }) => {
          this.upcoming_updates_posts_next_page_url = data.next_page_url
          this.upcoming_updates_posts.push(...data.data)
        })
        .finally(() => (this.upcoming_updates_posts_loading_more = false))
    },
    fetchRecentUpdates() {
      this.recent_updates_posts_loading = true
      request
        .get(`api/supports/post?type=updates`)
        .then(({ data }) => {
          this.recent_updates_posts_next_page_url = data.next_page_url
          this.recent_updates_posts = data.data
        })
        .finally(() => (this.recent_updates_posts_loading = false))
    },
    fetchMoreRecentUpdates() {
      this.recent_updates_posts_loading_more = true
      request
        .get(this.recent_updates_posts_next_page_url)
        .then(({ data }) => {
          this.recent_updates_posts_next_page_url = data.next_page_url
          this.recent_updates_posts.push(...data.data)
        })
        .finally(() => (this.recent_updates_posts_loading_more = false))
    },
    savePost(payload) {
      this.handleSavePost(payload, (data) => {
        this.post_dialog = false
        if (this.activePostType === 'upcoming-updates') {
          this.upcoming_updates_posts.unshift(data.data)
        } else if (this.activePostType === 'updates') {
          this.recent_updates_posts.unshift(data.data)
        }
        this.appSnackbar('Post created')
      })
    },
    updatePost(payload) {
      this.handleUpdatePost(this.activePost.id, payload, (data) => {
        this.post_dialog = false
        if (data.data.type === 'upcoming-updates') {
          let index = this.upcoming_updates_posts.findIndex(
            (i) => i.id == data.data.id
          )
          if (~index) this.upcoming_updates_posts.splice(index, 1, data.data)
        } else if (data.data.type === 'updates') {
          let index = this.recent_updates_posts.findIndex(
            (i) => i.id == data.data.id
          )
          if (~index) this.recent_updates_posts.splice(index, 1, data.data)
        }
        this.activePost = data.data
        this.appSnackbar('Post updated')
      })
    },
    handleEdit(entry) {
      this.activePost = entry
      this.activePostType = entry.type
      this.post_dialog = true
    },
    handleCreate(type) {
      this.activePost = null
      this.activePostType = type
      this.post_dialog = true
    },
    handleDelete(item) {
      this.handleDeletePost(item, (data) => {
        if (item.type === 'updates') {
          let index = this.recent_updates_posts.findIndex(
            (i) => i.id === item.id
          )
          if (~index) this.recent_updates_posts.splice(index, 1)
        } else if (item.type === 'upcoming-updates') {
          let index = this.upcoming_updates_posts.findIndex(
            (i) => i.id === item.id
          )
          if (~index) this.upcoming_updates_posts.splice(index, 1)
        }
      })
    }
  }
}
</script>

<style lang="scss"></style>
